import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ApiOfferSet, ApiPoll, ApiPollChoice, ApiPollQuestion, ApiTopic, ApiUser, OfferSetsApi, PollsApi } from '@tytapp/api';
import { BaseComponent, FormInput, MessageDialogComponent } from '@tytapp/common';
import { slugify } from '@tytapp/common/slugify';
import { isClientSide } from '@tytapp/environment-utils';
import { TopicsService } from '@tytapp/topics';
import { UserService } from '@tytapp/user';
import { PollMobilePreviewComponent } from '../poll-mobile-preview/poll-mobile-preview.component';

interface TypeLabels {
    title: string;
    caption: string;
    description: string;
    question: string;
}

const PETITION_LABELS: TypeLabels = {
    title: 'Title',
    caption: 'Abstract',
    description: 'About This Petition',
    question: 'Question'
};

const POLL_LABELS: TypeLabels = {
    title: 'Title',
    caption: 'Caption',
    description: 'Description',
    question: 'Content'
}

@Component({
    selector: 'tyt-poll-editor',
    templateUrl: './poll-editor.component.html',
    styleUrls: ['./poll-editor.component.scss']
})
export class PollEditorComponent extends BaseComponent {
    private location = inject(Location);
    private userService = inject(UserService);
    private pollsApi = inject(PollsApi);
    private topicsService = inject(TopicsService);
    private offerSetsApi = inject(OfferSetsApi);

    get typeLabels() {
        if (this.poll && this.poll.type === 'Petition')
            return PETITION_LABELS;

        return POLL_LABELS;
    }

    inBrowser = isClientSide();

    @FormInput()
    title: string = "";

    updateSuccessMessage: string = '';
    edit: boolean = false;

    @Input()
    user: ApiUser;

    @Input()
    showDescriptionField = true;

    @Input()
    showSlugField = true;

    @Input()
    showTitleField = true;

    @Input()
    allowUserSelection = true;

    @Input()
    allowTopicSelection = true;

    @Input()
    allowBackdropSelection = true;

    @Input()
    get poll(): ApiPoll {
        return this._poll;
    }

    set poll(value) {
        this._poll = value;
        this._savedPollStr = JSON.stringify(value);
        this._savedPoll = JSON.parse(this._savedPollStr);

        value.questions ??= [];

        for (let question of value.questions) {
            if (question.max_choices_count === undefined)
                question.max_choices_count = 1;
        }
    }

    private _poll: ApiPoll;
    private _savedPollStr: string;
    private _savedPoll: ApiPoll;

    get savedPoll() {
        return this._savedPoll;
    }

    private _goalAmount: number;

    set goalAmount(value: number) {
        this._goalAmount = value;
        if (this._poll.goal === null && value !== null) {
            this._poll.goal = { number: Math.round(value), milestones: null };
        }

        if (this._poll.goal != null && this._poll.goal.number !== value) {
            this._poll.goal.number = Math.round(value);
        }
    }

    get goalAmount(): number {
        return this._goalAmount;
    }

    get milestones() {
        if (this._poll && this._poll.goal && this._poll.goal.milestones) {
            return this._poll.goal.milestones.filter((item) => !item._destroy);
        } else {
            return [];
        }
    }

    showMobilePreview() {
        this.shell.showDialog(PollMobilePreviewComponent, this.poll);
    }
    async loadTopics() {
        this.topics = await this.topicsService.all();
        let prec = {
            topic: 1,
            politician: 2,
            host: 3
        };
    }

    get humanReadableStatus() {
        let statusMap = {
            draft: 'Draft',
            published: 'Published',
            closed: 'Closed'
        };

        return statusMap[this.poll.status] || this.poll.status;
    }

    topics: ApiTopic[];

    get undeletedQuestionCount() {
        if (!this.poll)
            return 0;

        return this.poll.questions.filter(x => !x['_destroy']).length;
    }

    humanizeType(type) {
        let map = {
            ChooseOnePoll: 'Choose One Poll',
            Petition: 'Petition'
        };

        return map[type] || type;
    }

    async init() {
        await this.userService.ready;
        this.user = this.userService.user;
        await this.loadTopics();
        await this.loadEmbeddedOffers();
        this.subscribe(this.userService.userChanged, user => this.user = user);
    }

    get byStaff() {
        if (!this.poll || !this.poll.author)
            return false;

        if (this.poll.author.staff)
            return true;

        return false;
    }

    get bylineAvatar() {
        if (this.user && this.user.profile && this.user.profile.avatar)
            return this.user.profile.avatar;

        return '/assets/new-avatar.png';
    }

    removeOption(question: ApiPollQuestion, option: ApiPollChoice) {
        option['_destroy'] = 1;
    }

    removeQuestion(question: ApiPollQuestion) {
        question['_destroy'] = 1;
    }

    get isDraftable() {
        if (!this.poll)
            return false;

        if (['published', 'closed'].includes(this.poll.status))
            return false;

        return true;
    }

    get isPublished() {
        return ['published', 'open', 'closed'].includes(this.poll.status);
    }

    async publishValidationCheck() {
        let errors = [];

        this.errorMessages = errors;
        return errors.length === 0;
    }

    clearErrors() {
        this.errorMessages = [];
    }

    errorMessages: string[];

    humanizeStatus(status) {
        let map = {
            draft: 'Draft',
            published: 'Published',
            open: 'Open for voting',
            closed: 'Voting closed'
        };

        if (this.poll && this.poll.type === 'Petition') {
            map['open'] = 'Sign now';
            map['closed'] = 'Campaign ended';
        }

        return map[status] || status;
    }

    revert() {
        if (!confirm('Are you sure you want to revert?'))
            return;

        this.poll = this.savedPoll;
    }

    async unpublish() {
        this.clearErrors();
        try {
            this.poll = await this.pollsApi.triggerPollEvent(this.poll.slug, 'unpublish').toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            if (e.error === 'validation') {
                this.errorMessages = e.messages;
            } else {
                let message = e.message || e.error;
                alert(`Failed to open poll for voting: ${message || 'An unknown error occurred. Please try again or contact support@tytnetwork.com.'}`);
            }

            return false;
        }

        return true;
    }

    async reopen() {
        this.clearErrors();
        try {
            this.poll = await this.pollsApi.triggerPollEvent(this.poll.slug, 'reopen').toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            if (e.error === 'validation') {
                this.errorMessages = e.messages;
            } else {
                let message = e.message || e.error;
                alert(`Failed to open poll for voting: ${message || 'An unknown error occurred. Please try again or contact support@tytnetwork.com.'}`);
            }

            return false;
        }

        return true;
    }

    async close() {
        this.clearErrors();
        try {
            this.poll = await this.pollsApi.triggerPollEvent(this.poll.slug, 'close').toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            if (e.error === 'validation') {
                this.errorMessages = e.messages;
            } else {
                let message = e.message || e.error;
                alert(`Failed to open poll for voting: ${message || 'An unknown error occurred. Please try again or contact support@tytnetwork.com.'}`);
            }

            return false;
        }

        return true;
    }

    get maxChoices() {
        return 1000;
    }

    get maxQuestions() {
        return 1000;
    }

    saving = false;
    enableMissions = true;

    async save() {
        if (this.saving)
            return;
        this.saving = true;

        this.poll.slug ||= slugify(this.poll.title);

        try {
            if (this._poll.goal && this._poll.goal.number == null && this._poll.goal.milestones && this._poll.goal.milestones.length > 0) {
                return alert('The Goal Amount is missing');
            }

            let questionOrder = 0;
            for (let question of this.poll.questions) {
                question.position = questionOrder++;
                let choiceOrder = 0;
                for (let choice of question.choices) {
                    choice.position = choiceOrder++;
                }
            }

            if (!this.savedPoll || !this.savedPoll.slug) {
                let result = await this.pollsApi.createPoll(this.poll).toPromise();
                this.poll = result;
                this.location.replaceState(`/polls/${this.poll.slug}/edit`);
            } else {
                let oldSlug = this.savedPoll.slug;
                let result = await this.pollsApi.updatePoll(oldSlug, this.poll).toPromise();
                this.poll = result;
                this.location.replaceState(`/polls/${this.poll.slug}/edit`);
            }
        } finally {
            this.saving = false;
        }
    }

    get bylineName() {
        if (this.byStaff)
            return 'TYT Staff';

        if (this.user)
            return this.user.display_name;

        return 'Anonymous';
    }

    reorderQuestions(event) {
        moveItemInArray(this.poll.questions, event.previousIndex, event.currentIndex);
    }

    reorderOptions(event, question) {
        moveItemInArray(question.choices, event.previousIndex, event.currentIndex);
    }

    get isPersisted() {
        return this.poll && !!this.poll.status;
    }

    addQuestion() {
        this.poll.questions.push(<any>{
            body: '',
            image: null,
            opens_at: null,
            closes_at: null,
            choices: []
        });
    }

    addOption(question: ApiPollQuestion) {
        question.choices.push(<any>{
            body: '',
            image: null,
            votes_count: 0
        });
    }

    showReference() {
        this.shell.showDialog(
            MessageDialogComponent,
            'Markdown Reference',
            'You can use Markdown in the Poll Description. **Bold**, __Italic__, [Link Text](https://example.com/)'
        );
    }

    get canHaveMissions() {
        return this.poll.type === 'Petition';
    }

    get canHaveQuestions() {
        return this.poll.type !== 'Petition';
    }

    get canRequireMembershipToVote() {
        return this.poll.type !== 'Petition';
    }

    get canVotesBeChanged() {
        return this.poll.type !== 'Petition';
    }

    addMilestone() {
        if (this._poll.goal === null) {
            this._poll.goal = { number: null, milestones: null };
        }

        if (this._poll.goal.milestones === null) {
            this._poll.goal.milestones = [];
        }

        this._poll.goal.milestones.push({
            label: null,
            percentage: null
        });
    }

    deleteMilestone(index: number) {
        if (this._poll.goal && this._poll.goal.milestones && this._poll.goal.milestones.length > index) {
            this._poll.goal.milestones[index]._destroy = true;
        }
    }

    embeddedOffers: ApiOfferSet[];

    loadEmbeddedOffers() {
        this.offerSetsApi.getAllEmbeddedOfferSets().subscribe(offers => this.embeddedOffers = offers);
    }

    updateEndorsements(endorsements) {
        this.poll.endorsements = endorsements;
    }

    createNewMission() {
        if (this.poll.active_mission) {
            if (!confirm(`Are you sure you wish to start a new mission?`))
                return;
        }

        this.poll.active_mission = {
            share_targets: this.poll?.active_mission?.share_targets ?? ['facebook', 'twitter', 'reddit']
        };
        this.shareTargets = this.newShareTargetProxy();
    }

    shareTargets = this.newShareTargetProxy();

    private newShareTargetProxy() {
        return new Proxy({} as any, {
            get: (target, key: string) => {
                if (target[key] === undefined) {
                    const targets = this.poll.active_mission.share_targets ??= [];
                    target[key] = targets.includes(key);
                }
                return target[key];
            },
            set: (target, key: string, value) => {
                target[key] = value;
                const targets = this.poll.active_mission.share_targets;
                if (value) {
                    targets.push(key);
                } else {
                    targets.splice(targets.indexOf(key), 1);
                }
                return true;
            }
        });
    }

    get pollSlug() {
        return this.poll.slug || slugify(this.poll.title);
    }

    set pollSlug(value) {
        this.poll.slug = value;
    }

    @ViewChild('slugField', { read: NgModel })
    slugField: NgModel;

    conformSlug() {
        if (this.slugField.dirty) {
            this.poll.slug = slugify(this.poll.slug);
        }
    }
}
