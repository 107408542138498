import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, Output } from '@angular/core';
import { ApiTalent, TalentApi } from '@tytapp/api';
import { Subject } from 'rxjs';

@Component({
    selector: 'tyt-endorsements-editor',
    templateUrl: './endorsements-editor.component.html',
    styleUrls: ['./endorsements-editor.component.scss']
})
export class EndorsementsEditorComponent {

    localEndorsements: ApiTalent[];

    @Input()
    set endorsements(value: ApiTalent[]) {
        this.localEndorsements = value;
    }

    @Output()
    changed = new Subject<ApiTalent[]>();

    searchResult: ApiTalent[];

    query: string;

    TALENT_SEARCH_COUNT = 5;

    constructor(
        private talentApi: TalentApi
    ) { }

    async search(query: string) {
        this.searchResult = await this.talentApi.all(query, this.TALENT_SEARCH_COUNT).toPromise();
    }

    add(id: number) {
        if (!this.localEndorsements) {
            this.localEndorsements = [];
        }

        this.localEndorsements.push(this.searchResult[id]);
        this.searchResult.splice(id, 1);
        this.changed.next(this.localEndorsements);
    }

    remove(id: number) {
        if (!this.searchResult) {
            this.searchResult = [];
        }
        this.searchResult.push(this.localEndorsements[id]);
        this.localEndorsements.splice(id, 1);
        this.changed.next(this.localEndorsements);
    }

    reorder(event) {
        moveItemInArray(this.localEndorsements, event.previousIndex, event.currentIndex);
        this.changed.next(this.localEndorsements);
    }
}
