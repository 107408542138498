import { NgModule } from '@angular/core';
import { PollEditorComponent } from './poll-editor/poll-editor.component';
import { EditorCommonModule } from '@tytapp/editor-common';
import { CommonModule } from '@angular/common';
import { TYTCommonModule } from '@tytapp/common';
import { CommonUiModule } from '@tytapp/common-ui';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { EndorsementsEditorComponent } from './endorsements-editor/endorsements-editor.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PollMobilePreviewComponent } from './poll-mobile-preview/poll-mobile-preview.component';
import { PollsModule } from '@tytapp/polls';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

const DECLARATIONS = [
    PollEditorComponent,
    EndorsementsEditorComponent,
    PollMobilePreviewComponent
]
@NgModule({
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    imports: [
        CommonModule,
        FormsModule,
        TYTCommonModule,
        CommonUiModule,
        EditorCommonModule,
        PollsModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatDividerModule,
        MatCheckboxModule,
        DragDropModule

    ]
})
export class PollEditorModule {

}