<div class="endorsement-editor">
  <div class="endorsement-search-result endorsement-container">
    <h2>Available</h2>
    <form id="search" (submit)="search(query)">

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Search</mat-label>
        <input matInput type="text" name="query" [(ngModel)]="query">
      </mat-form-field>

      <button mat-icon-button class="btn">
        <mat-icon>search</mat-icon>
      </button>
    </form>

    <!-- Empty cases -->

    @if (!searchResult) {
      <div class="endorsement-notice">
        Search above to find people to add.
      </div>
    }
    @if (searchResult?.length === 0) {
      <div class="endorsement-notice">
        No results found
      </div>
    }

    <!-- Results -->

    <div class="endorsement-list">
      @for (endorsement of searchResult; track endorsement; let id = $index) {
        <button mat-button (click)="add(id)">
          <tyt-endorsement-item class="dark-background" [endorsement]="endorsement"></tyt-endorsement-item>
        </button>
      }
    </div>
  </div>
  <div class="endorsement-current endorsement-container">
    <h2>Added</h2>
    @if (!localEndorsements || localEndorsements.length === 0) {
      <div class="endorsement-notice">The petition doesn't
      have any endorsements yet</div>
    }
    <div class="endorsement-list" cdkDropList (cdkDropListDropped)="reorder($event)">
      @for (endorsement of localEndorsements; track endorsement; let id = $index) {
        <div class="endorsement-item-current" cdkDrag>
          <button mat-icon-button cdkDragHandle>
            <mat-icon>menu</mat-icon>
          </button>
          <tyt-endorsement-item class="dark-background" [endorsement]="endorsement"></tyt-endorsement-item>
          <a mat-icon-button href="javascript:;" (click)="remove(id)">
            <mat-icon>close</mat-icon>
          </a>
        </div>
      }
    </div>
  </div>
</div>