import { Component } from "@angular/core";
import { DialogComponent, NamedDialog } from "@tytapp/common";
import { ApiPoll } from "@tytapp/api";

@NamedDialog('pollMobilePreview')
@Component({
    template: `
        <h1>Mobile Preview</h1>
        
        <div class="scroller">
          @if (!poll) {
            <app-spinner></app-spinner>
          }
          @if (poll) {
            <tyt-poll [mini]="true" [preview]="true" [poll]="poll"></tyt-poll>
          }
        </div>
        `,
    styles: [
        `
            tyt-poll {
                pointer-events: none;
            }

            .scroller {
                max-height: 85vh;
                overflow-y: auto;
            }
        `
    ]
})
export class PollMobilePreviewComponent extends DialogComponent {
    poll: ApiPoll;

    init(poll: ApiPoll) {
        this.poll = poll;
    }
}